<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import OrSeparator from '@/Components/OrSeparator.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SsoButton from '@/Components/SsoButton.vue';
import TextInput from '@/Components/TextInput.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
  canResetPassword: Boolean,
  status: String,
});

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const submit = () => {
  form.post(route('login'), {
    onFinish: () => form.reset('password'),
  });
};

function attemptSsoLogin(provider) {
  location.href = route('sso.login', provider);
}
</script>

<template>
  <GuestLayout>
    <Head title="ZeroKey | Log in" />

    <div v-if="status" class="mb-4 font-medium text-sm text-slate-900">
      {{ status }}
    </div>

    <h1 class="mb-2 font-bold text-slate-900 text-xl">
      Log in to your account
    </h1>

    <form @submit.prevent="submit">
      <div class="mb-3 text-sm text-slate-800">
        Don't have an account?
        <Link href="/register" class="text-blue-600 font-medium">
          Register here
        </Link>
      </div>

      <div class="mt-4">
        <SsoButton @click.native="() => attemptSsoLogin('microsoft')">
          <img :src="asset('images/ms-logo.svg')" class="w-5 h-5" />
          <span>Log in with Microsoft</span>
        </SsoButton>
      </div>

      <OrSeparator class="mt-4 mb-2.5" />

      <div>
        <InputLabel for="email" value="Email" class="mb-1.5" />

        <TextInput
          id="email"
          v-model="form.email"
          type="email"
          class="block w-full"
          required
          autofocus
          autocomplete="username"
        />

        <InputError class="mt-2" :message="form.errors.email" />
      </div>

      <div class="mt-4">
        <div class="flex items-center mb-1.5">
          <InputLabel for="password" value="Password" />

          <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="ml-auto text-sm text-blue-600 hover:text-blue-900 font-medium rounded-md"
          >
            Forgot your password?
          </Link>
        </div>

        <TextInput
          id="password"
          v-model="form.password"
          type="password"
          class="block w-full"
          required
          autocomplete="current-password"
        />

        <InputError class="mt-2" :message="form.errors.password" />
      </div>

      <div class="block mt-4">
        <label class="flex items-center">
          <Checkbox v-model:checked="form.remember" name="remember" />
          <span class="ml-2 text-sm text-slate-600">Remember me</span>
        </label>
      </div>

      <div class="flex items-center justify-end mt-4">
        <PrimaryButton
          class="w-full justify-center"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          Log in
        </PrimaryButton>
      </div>
    </form>
  </GuestLayout>
</template>
